import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { WindowRefService } from '../windows-ref/window-ref.service';

/**
 * Initializer function that is responsible for redirecting unauthorized users to relaunch app after logged in
 * @param cookieService CookieService
 * @param auth AuthService
 * @param winRef WindowRefService
 */
export function cookieRedirectInitializer(cookieService: CookieService, auth: AuthService, winRef: WindowRefService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise<void>((resolve, reject) => {
            const sdm_nav_ctxStr = cookieService.get(environment.SDM_NAV_CTX_COOKIE_NAME);
            const jwtStr = cookieService.get(environment.OOKA_SERVICES_JWT_COOKIE_NAME);
            if (!sdm_nav_ctxStr || !jwtStr) {
                const env = winRef.nativeWindow.location.hostname.split('.')[2];
                const sdmBaseURL = auth.getSDMBaseURLByEnv(env);
                winRef.nativeWindow.location.href = sdmBaseURL + '/#/launchApp?app=ooka';
                reject('Not logged in');
            } else {
                resolve();
            }
        });
    };
}
