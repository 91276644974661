import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

@Pipe({
    name: 'tableColumnOrder'
})
export class TableColumnOrderPipe implements PipeTransform {
    transform(columns: any[], defaultOrder?: any[]): any[] {
        if (!columns || !columns.length) {
            return [];
        }
        let columnOrderIsMissing = false;
        columns.forEach((column) => {
            if (column.order === undefined) {
                columnOrderIsMissing = true;
            }
        });
        const orderedKeys = [];
        if (columnOrderIsMissing) {
            if (defaultOrder) {
                return defaultOrder;
            }
            columns.forEach((column) => {
                orderedKeys.push(column.prop);
            });
        } else {
            orderBy(columns, 'order', 'asc').forEach((column) => {
                orderedKeys.push(column.prop);
            });
        }
        return orderedKeys;
    }
}
