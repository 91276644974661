import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';


import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
    document.write(environment.NEW_RELIC_OOKA_DASHBOARD_PROD_PRO_AGENT_SCRIPT);
} else {
    document.write(environment.NEW_RELIC_OOKA_DASHBOARD_DEV_PRO_AGENT_SCRIPT);
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
