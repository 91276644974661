<div class="Uh-oh-Something-wen">
  <img src="assets/img/group-8@3x.png" class="student-image">
  <div class="text-style-1">Coming Soon: New Back-To-School Features!</div>
  <div>Scholastic F.I.R.S.T. reports are currently unavailable.
    Students can continue to access Scholastic F.I.R.S.T. without any interruptions and without any data loss.</div><br>
  <div>
    We apologize for the inconvenience, but are working hard to release updates to Scholastic F.I.R.S.T. for
    back-to-school.
    Please check back soon to access new features and enhancements to the Scholastic F.I.R.S.T. reports.
  </div>
</div>