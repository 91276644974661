import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class EducatorLoadingGuard implements CanLoad {
  private readonly allowedRoles = ['districtAdmin', 'schoolAdmin', 'teacher'];

  constructor(private auth: AuthService, private router: Router) { }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const role = this.auth.topLevelParam.role;
    const allowed = this.allowedRoles.indexOf(role) !== -1 && !this.auth.isDemoUser();
    if (!allowed) {
      if (role === 'student') {
        this.router.navigate(['landing'], { skipLocationChange: true });
      } else if (this.auth.isDemoUser()) {
        this.router.navigate(['demo']);
      }
    }
    return allowed;
  }
}
