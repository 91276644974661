import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class ErrorService {
    errorHeader = '';
    errorMessage = '';
    errorMessageLine2 = '';
    constructor(private router: Router) { }

    /**
     * This function will set the error message.
     * @returns void
     */
    setErrorMessage(errHeader?: string, errMsg?: string, errMsgLine2?: string, reports?: boolean) {
        this.errorHeader = (errHeader) ? errHeader : '';
        this.errorMessage = (errMsg) ? errMsg : '';
        this.errorMessageLine2 = (errMsgLine2) ? errMsgLine2 : '';

        const options = { skipLocationChange: true };
        if (reports) {
            options['queryParams'] = { return: 'reports' };
        }
        this.router.navigate(['/error'], options);
    }
    /**
     * This function will get the error message.
     * @returns void
     */
    getErrorMessage() {
        return this.errorMessage;
    }
}
