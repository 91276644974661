import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class RequestsService {

    constructor(private http:HttpClient) {
    }

    getContentFromUrl(urlName: string, callback?: any) {
        this.http.request("GET", urlName, {responseType: 'text'}).toPromise().then( result => {
            if (callback) {
                callback(result);
            }
        })
    }
}
