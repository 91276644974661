import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ResizeSensor } from 'css-element-queries';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  @ViewChild('imgContainer', { static: true }) imgContainer: ElementRef;
  @ViewChild('areaElement', { static: true }) areaElement: ElementRef;
  gameLaunchParams: string;
  apiURL: string;
  mapAreaCoordRatios = [0.2421875, 0.677734375, 0.763671875, 0.837890625];
  lastWidth: number;
  sensor: any;
  showMessage = false;

  constructor(private auth: AuthService, private renderer: Renderer2, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.apiURL = this.auth.apiURL;
    this.gameLaunchParams = '?token=' + this.auth.authToken + '&env=' + this.auth.env;
    this.sensor = new ResizeSensor(this.imgContainer.nativeElement, () => {
      this.resetMapAreaCoords();
    });
    if (this.activatedRoute.snapshot.queryParams.showMessage) {
        this.showMessage = true;
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {}, replaceUrl: true });
    }
  }

  ngOnDestroy() {
    this.sensor.detach();
  }

  /*
    Resets clickable map area for image
    @returns void
  */
 resetMapAreaCoords(): void {
   const imgWidth = this.imgContainer.nativeElement.offsetWidth;
   if (imgWidth && imgWidth !== this.lastWidth) {
    const coords = this.mapAreaCoordRatios.map(c => c * imgWidth).join(',');
    this.renderer.setAttribute(this.areaElement.nativeElement, 'coords', coords);
    this.lastWidth = imgWidth;
   }
 }
}
