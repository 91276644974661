import { Component, OnInit, OnDestroy, Renderer2, HostListener, ElementRef, ViewChild } from '@angular/core';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error-service/error-service';
import { Router } from '@angular/router';
import { WindowRefService } from '../windows-ref/window-ref.service';

@Component({
  selector: 'app-game-launcher',
  templateUrl: './game-launcher.component.html',
  styleUrls: ['./game-launcher.component.scss']
})
export class GameLauncherComponent implements OnInit, OnDestroy {
  /*
   * On iOS, we don't want this component to be loaded. If the user has the Ooka Island app installed and they
   * click a link to /launcher/ the phone should hijack that link and open the application.
   */
  currentDevice: string;
  supportedDevices = ['unknown', 'chrome-book', 'macintosh'];
  supportedBrowsers = [BROWSERS.FIREFOX, BROWSERS.CHROME, BROWSERS.SAFARI, BROWSERS.MS_EDGE, BROWSERS.MS_EDGE_CHROMIUM];
  resized = false;
  unityJSLoaded = false;
  @ViewChild('canvas', {
     static: true
  })
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('container', {
    static: true
 })
  container: ElementRef<HTMLElement>;
  appFooterSize = 156;
  assetsBaseURL: string;
  logo: HTMLImageElement;
  progress: HTMLDivElement;
  progressFull: HTMLDivElement;
  progressEmpty: HTMLDivElement;

  bodyListener: () => void;
  documentListener: () => void;

  constructor(
    private deviceService: DeviceDetectorService,
    private auth: AuthService,
    private errorService: ErrorService,
    private router: Router,
    private window: WindowRefService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    const device = this.deviceService.getDeviceInfo();
    this.currentDevice =
      this.deviceService.isTablet() && this.deviceService.os === 'Mac'
        ? 'ipad'
        : device.device.toLowerCase();

    this.resizeGameCanvas();

    this.assetsBaseURL =
      environment.WEBGL_ASSETS_URL +
      this.auth.user.webglVersion +
      (this.currentDevice === 'chrome-book' ? '/Low' : '');
    this.loadUnityJs().then((result) => this.onUnityJSLoaded(result)).catch(e => this.errorService.setErrorMessage(e));
  }

  ngOnDestroy() {
    // remove audio element before destroy game component.
    if (this.window.nativeWindow.document && this.window.nativeWindow.document.getElementById('audio_0')) {
      this.window.nativeWindow.document.getElementById('audio_0').remove();
    }
  }

  /**
   * Deals with the loaded UnityLoader.js and start the loading of the game. It also filters unsupported
   * browsers as well as directs mobile users to the proper universal links or mobile stores
   * @param result Object with information regarding the sucess of the loading or not
   */
  onUnityJSLoaded(result: any) {
    if (result.loaded) {
      if (this.supportedDevices.includes(this.currentDevice)) {
        if (
          this.supportedBrowsers.indexOf(this.deviceService.browser) ===
          -1
        ) {
          this.errorService.setErrorMessage(
            'Unfortunately your browser doesn’t support Scholastic F.I.R.S.T.',
            `We recommend that you use a supported browser such as Chrome, Firefox, Safari or Edge to use this program.
                        If you have any questions or concerns, please contact Customer Service at 1-800-724-2222 or email
                        digitalservice@scholastic.com`
          );
        } else {
          this.gameInstantiation();
        }
      } else {
        const redirect = this.appStoreRedirect(this.currentDevice);
        if (redirect !== null) {
          this.window.nativeWindow.location.href = redirect;
          setTimeout(() => {
            let queryParams = {};
            if (
              this.deviceService.browser === BROWSERS.SAFARI &&
              (this.currentDevice === 'ipad' || this.currentDevice === 'iphone')
            ) {
              queryParams = { showMessage: true };
            }
            this.router.navigate(['landing'], { skipLocationChange: true, queryParams });
          }, 500);
        } else {
          this.errorService.setErrorMessage(
            `Unfortunately your device ${this.currentDevice} doesn’t support
                        Scholastic F.I.R.S.T.`,
            `We recommend that you use a supported device such as iOS or Android or
                        a supported browser such as Chrome, Firefox, Safari or Edge to use this program. If you have
                        any questions or concerns, please contact Customer Service at 1-800-724-2222 or email
                        digitalservice@scholastic.com`
          );
        }
      }
    }
  }

  /**
   * Creates a promise for the UnityLoader.js to be loaded;
   */
  loadUnityJs() {
    return new Promise((resolve, reject) => {
      if (!this.unityJSLoaded) {
        // Element where the loaded script will reside
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.assetsBaseURL + '/Build/OokaIslandSE_1.loader.js';
        script.className = 'UnityLoaderJS';
        script.onload = () => {
          this.unityJSLoaded = true;
          resolve({ script: 'UnityLoader.js', loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) =>
          reject({ script: 'UnityLoaderJS', loaded: false, error, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: 'UnityLoader.js', loaded: true, status: 'Already Loaded' });
      }
    });
  }

  appStoreRedirect(device: string): string {
    if (device === 'iphone' || device === 'ipad') {
      return environment.iosAppStoreLocation;
    } else if (device === 'android') {
      return environment.androidPlayStoreLocation;
    }

    return null;
  }

  /**
   * Starts the Unity WebGL creation by using the UnityLoader helper class.
   */
  gameInstantiation(): Promise<unknown> {
    this.setupAudioContextHook();
    // This call is used with unity Loader to get the ooka game container running
    return this.window.nativeWindow.createUnityInstance(this.canvas.nativeElement, {
        dataUrl: this.assetsBaseURL + "/Build/OokaIslandSE_1.data.unityweb",
        frameworkUrl: this.assetsBaseURL + "/Build/OokaIslandSE_1.framework.js.unityweb",
        codeUrl: this.assetsBaseURL + "/Build/OokaIslandSE_1.wasm.unityweb",
        streamingAssetsUrl: this.assetsBaseURL + "/StreamingAssets",
        companyName: "Scholastic",
        productName: "F.I.R.S.T.",
        productVersion: "1.0.0"
      }, this.unityProgress.bind(this)).then(gameInstance => {
        // Hide the loading bar and the logo as unity have finished loading
        if (this.logo) {
            this.logo.style.display = this.progress.style.display = 'none';
        }
      }).catch(e => this.errorService.setErrorMessage(e));
  }

  /**
   * Callback for the UnityLoader to be called at each tick of the loading process.
   * @param gameInstance Object with the most important references for the WebGL game.
   * @param progress Number that varies from 0 to 1 representing the progress of the UnityLoader.
   */
  unityProgress(progress): void {
    const container = this.container;

    if (!this.logo) {
      // Creates the logo image to display on top of the loading bar
      this.logo = document.createElement('img');
      this.logo.className = 'loadingLogo';
      this.logo.src = '../../assets/img/OokaLoadingLogo.png';
      container.nativeElement.appendChild(this.logo);
    }
    if (!this.progress) {
      // Creates the loading bar so we can display the progress of the unity loader
      this.progress = document.createElement('div');
      this.progress.className = 'loadingProgress';
      this.progressEmpty = document.createElement('div');
      this.progressEmpty.className = 'empty';
      this.progress.appendChild(this.progressEmpty);
      this.progressFull = document.createElement('div');
      this.progressFull.className = 'full';
      this.progress.appendChild(this.progressFull);
      container.nativeElement.appendChild(this.progress);
    }
    // Set the size of the progress bar (ignoring the borders of 2 pixels each)
    this.progressFull.style.width = 'calc(' + 100 * progress + '% - 4px)';
  }

  /**
   * Callback attached to teh browser event of screen resizing.
   * @param event Object with relevant data about the window resize.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.resizeGameCanvas();
  }

  /**
   * Resizes the Unity WebGL canvas to fit the user browser in the best way possible.
   */
  resizeGameCanvas(): void {
    const expectedHeight = this.window.nativeWindow.innerHeight - this.appFooterSize;
    const expectedWidth = Math.min(this.window.nativeWindow.innerWidth, expectedHeight * 2);

    const sizeStyle =
      'width: ' + expectedWidth + 'px; height: ' + expectedHeight + 'px; margin: auto;';
    this.element.nativeElement.setAttribute('style', sizeStyle);

    this.canvas.nativeElement.setAttribute('style', sizeStyle);
    this.canvas.nativeElement.setAttribute('height', expectedHeight.toString());
    this.canvas.nativeElement.setAttribute('width', expectedWidth.toString());
  }

  /**
   * Hooks AudioContext window function so we can "steal" access to the games audio context
   */
  setupAudioContextHook() {
    const win = this.window.nativeWindow;

    const bind = Function.bind;
    const unbind = bind.bind(bind);

    const instantiate = (constructor, args) => {
      return new (unbind(constructor, null).apply(null, args))();
    };

    if (this.deviceService.browser === BROWSERS.SAFARI) {
      win['AudioContext'] = ((AudioContext) => {
        return (...args) => {
          const audioContext = (win['myAudioContext'] = instantiate(AudioContext, args));
          // Below code hooks AudioContext.createGain method to start listening to events only after volume is set
          audioContext.createGainOrig = audioContext.createGain;
          audioContext.createGain = () => {
            this.bodyListener = this.renderer.listen('body', 'click', this.resumeAudio.bind(this));
            this.documentListener = this.renderer.listen(
              'document',
              'keydown',
              this.resumeAudio.bind(this)
            );
            const result = audioContext.createGainOrig();
            audioContext.createGain = audioContext.createGainOrig;
            audioContext.createGainOrig = undefined;
            return result;
          };
          return audioContext;
        };
      })(win['AudioContext']);
    }
  }

  /**
   * Resumes game audio context because on safari mac it starts suspended
   */
  resumeAudio() {
    const myAudioContext = this.window.nativeWindow['myAudioContext'];
    if (myAudioContext) {
      myAudioContext.resume();
      this.bodyListener();
      this.documentListener();
    }
  }
}
