import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

    /**
     * DataService [StateService?] is a service to
     * - coordinate multiple sets of simultaneous data requests
     * - return report state such as
     * -- last report or
     * -- last report level or
     * -- last report level chart toggle
     */
    constructor() { }

    /** Phonological Reports Requests
     * District Level
     * - Components: Table, Skills Scores, Distribution, Progress
     * School Level
     * - Components: Table, Skills Scores, Distribution, Progress
     * Teacher Level
     * - Components: Table, Skills Scores, Distribution, Progress
     * Section Level
     * - Components: Table, Skills Scores, Distribution, Progress
     * Student Level
     * - Components: Table, Skills Scores, Distribution, Progress
     */

}
