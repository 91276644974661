import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shingle',
  templateUrl: './shingle.component.html',
  styleUrls: ['./shingle.component.scss']
})
export class ShingleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
