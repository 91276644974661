import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class DemoLoadingGuard implements CanLoad {
  constructor(private auth: AuthService, private router: Router) { }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const role = this.auth.topLevelParam.role;
    const allowed = this.auth.isDemoUser();
    if (!allowed) {
      if (role === 'student') {
        this.router.navigate(['landing'], { skipLocationChange: true });
      } else {
        this.router.navigate(['educator']);
      }
    }
    return allowed;
  }
}
