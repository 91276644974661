import { Injectable } from '@angular/core';

@Injectable()
export class ProficiencyLevelsService {
    public names = [
        'Learning',
        'Practicing',
        'Proficient'
    ];
    public colorScheme = { domain: ['#31278f', '#0069c7', '#00aae4'] };
    public scores = ['< 60%', '60% - 70%', '≥ 70%'];

    constructor() { }
}
