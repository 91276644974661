import { filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WindowRefService } from '../windows-ref/window-ref.service';
import { Jwt } from '../interfaces/jwt.interface';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sdm-nav-bar',
  templateUrl: './sdm-nav-bar.component.html',
  styleUrls: ['./sdm-nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SdmNavBarComponent implements OnInit, ElementRef, AfterViewInit {
  public authenticatedFlag = false;
  public name = 'blank';
  public fullName = 'blank';
  public sdm_nav_ctx_cookie_name: string = environment.SDM_NAV_CTX_COOKIE_NAME;
  public ooka_service_jwt_name: string = environment.OOKA_SERVICES_JWT_COOKIE_NAME;
  public bypass_sdm_auth: boolean = environment.BYPASS_SDM_AUTH_COOKIES;
  public window: any;
  public userContext: any;
  jwt: Jwt;
  sdm_nav_ctxStr;
  jwtStr;
  authToken;
  env;
  appArray = [];
  loggedOut = false;
  jwtHelper = new JwtHelperService();

  constructor(
    private cookieService: CookieService,
    private auth: AuthService,
    // public window: Window,
    private winRef: WindowRefService,
    private deviceDetector: DeviceDetectorService,
    private router: Router
  ) {
    this.window = this.winRef.nativeWindow;
  }

  @ViewChild('sdmElement', { static: true })
  private el: ElementRef;
  public nativeElement: Node;
  public SDMConfig = function () {
    return {
      cookieName: this.sdm_nav_ctx_cookie_name,
      notifyLogOut: notifyLogout.bind(this),
      notifyStillActive: notifyStillActive.bind(this),
      shareToClassroomEnabled:
        this.auth.topLevelParam.role === 'teacher' && this.userContext.source === 'Google',

      // numSecondsBetweenStillActiveNotifications notify every 10 minutes
      numSecondsBetweenStillActiveNotifications: 600
    };

    /**
     * notifyLogout
     * add hook here for what you want to do when a user log out
     * e.g. make AJAX call to the local servers logOut function and clear the sdm_nav_ctx cookie
     * Return false to let SDM widget know NOT to redirect to the SDM Portal login page
     * Default behavior is to redirect
     */
    function notifyLogout(response: any) {
      const hostname = this.window.location.hostname;
      const options: CookieOptions = {
        domain: hostname.substr(hostname.indexOf('.'))
      };
      if (this.getCookie(this.sdm_nav_ctx_cookie_name) !== undefined) {
        this.deleteCookie(this.sdm_nav_ctx_cookie_name, options);
      }
      if (this.getCookie(this.ooka_service_jwt_name) !== undefined) {
        this.deleteCookie(this.ooka_service_jwt_name, options);
      }
      if (response.code === 'SESSION_ENDED_LOGOUT' && !this.loggedOut) {
        // tried to access app with outdated cookies
        const env = this.auth.user.servicesEnv;
        const sdmBaseURL = this.auth.getSDMBaseURLByEnv(env);
        this.window.location.href = sdmBaseURL + '/#/launchApp?app=ooka';
        return false; // tell sdm-nav-bar script to not redirect to default sdm portal
      }
      return true;
    }

    /**
     * notifyStillActive
     * Add code here for what you want to do when you get notification that session is still active
     * e.g. make AJAX call to the local servers extend session endpoint to keep the local session alive
     */
    function notifyStillActive() {
      console.log('SDMConfig notifyStillActive');
    }
  };

  /**
   * Logout click handler
   */
  onLogout() {
    this.loggedOut = true;
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  deleteCookie(key: string, options: CookieOptions = {}) {
    return this.cookieService.remove(key, options);
  }

  /**
   * Took SDMS launch mechanism to create the launch URLS based on the app code in the sdm cookie
   * @param appCode
   * @returns {string} URL
   */
  constructUrl(appCode: any) {
    const launchUrl =
      this.userContext.portalBaseUrl +
      '/resources/launch/?app=' +
      appCode +
      '&orgId=' +
      this.userContext.orgId;

    if (!this.userContext.staff) {
      /* User is a Student */
      return launchUrl;
    } else if (this.userContext.staff && !this.userContext.admin) {
      /* User is non-admin staff */
      return launchUrl + '&staff=true';
    } else if (this.userContext.staff && this.userContext.admin) {
      /* User is an admin staff */
      return launchUrl + '&staff=true&admin=true';
    } else {
      return launchUrl;
    }
  }

  /**
   * Loop through all the appCodes in the array and pass them to construct the URLs
   *  @returns void
   */
  appCodeInitialization(): void {
    this.userContext.appCodes.forEach((value) => {
      // remove ooka from this list
      if (this.userContext.appCode !== value) {
        this.appArray.push({ code: value, url: this.constructUrl(value) });
      }
    });
  }

  /**
   * If the user is authenticated, get the full name from the user in auth service
   * else not authenticated so delete cookies
   *  @returns void
   */
  getFullName(isAuthenticated) {
    if (isAuthenticated) {
      this.name = this.auth.user.familyName;
      this.fullName = this.auth.user.givenName + ' ' + this.auth.user.familyName;
      this.window['SDMConfig'] = this.SDMConfig();
    } else {
      // If the sdm_nav_ctx cookie is not set, we'll need the user to log in
      // redirect them to SDM
      this.deleteCookie(this.sdm_nav_ctx_cookie_name);
      this.deleteCookie(this.ooka_service_jwt_name);
      // TODO: deleteCookies during app switching? maybe only delete ooka jwt?
      this.window.location.href = environment.sdm_location;
    }
  }

  ngOnInit() {
    this.sdm_nav_ctxStr = this.getCookie(this.sdm_nav_ctx_cookie_name);
    this.jwtStr = this.getCookie(this.ooka_service_jwt_name);
    if (this.jwtStr) {
      this.jwt = this.jwtHelper.decodeToken(this.jwtStr);
      this.auth.setAuth(this.jwtStr, this.sdm_nav_ctxStr, this.jwt.authToken, this.jwt.servicesEnv);
    }
    // copy the cookie to userContext so we don't use the original then call function to build launchUrls
    this.userContext = JSON.parse(this.sdm_nav_ctxStr);

    this.getFullName(this.auth.isAuthenticated);

    this.appCodeInitialization();
    const userRoles = {
      schoolAdmin: 'school admin',
      districtAdmin: 'district admin',
      teacher: 'educator',
      student: 'student'
    };

    const dumbleData = {
      version: '1',
      user: {
        loginStatus: true,
        userRole: userRoles[this.auth.user.dpRole],
        districtId: this.auth.user.districtId === undefined ? null : this.auth.user.districtId,
        schoolId: this.auth.user.schoolId === undefined ? null : this.auth.user.schoolId,
        sdmId: this.userContext.role === 'student' ? null : this.userContext.user_id,
        teacherSdmId:
          this.userContext.role === 'student' ? this.userContext.primaryTeacherIds : null,
        classId: this.userContext.role === 'student' ? this.userContext.classIds : null
      },
      page: {
        domain: this.window.location.hostname,
        destinationURL: this.window.location.href,
        isError: false,
        siteSection: this.userContext.role === 'student' ? 'game' : 'reports',
        pageName: this.userContext.role === 'student' ? 'Game Launch Screen' : '',
        timeFrame: null,
        audience: this.userContext.role === 'student' ? 'educator/student' : 'educator'
      },
      siteInfo: {
        environment: this.window.location.hostname.includes('prod') ? 'production' : 'dev',
        sysEnv: this.deviceDetector.isMobile() ? 'Mobile' : 'Desktop',
        name: 'FIRST'
      }
    };

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(this.sasAnaliticsNavigationUpdates.bind(this));

    this.winRef.nativeWindow['dumbleData'] = dumbleData;

    const script = document.createElement('script');
    script.src = 'https://digital.scholastic.com/resources/nav-widget/sdm-nav-bar.min.js';
    this.winRef.nativeWindow.document.head.appendChild(script);
  }

  /**
   * Callback for when the url changes for the dashboard to update the SAS analitics object;
   * @param navData navigation end event data
   */
  sasAnaliticsNavigationUpdates(navData: NavigationEnd) {
    this.winRef.nativeWindow['dumbleData'].page.destinationURL =
      this.window.location.origin + navData.urlAfterRedirects;

    this.winRef.nativeWindow['dumbleData'].page.timeFrame = null;

    if (navData.url.includes('error')) {
      this.winRef.nativeWindow['dumbleData'].page.isError = true;
    } else {
      this.winRef.nativeWindow['dumbleData'].page.isError = false;
    }

    if (navData.urlAfterRedirects.includes('reports')) {
      // Page name will be defined on the report controller;
      this.winRef.nativeWindow['dumbleData'].page.siteSection = 'reports';
      this.winRef.nativeWindow['dumbleData'].page.audience = 'educator';
    } else if (navData.urlAfterRedirects === '/') {
      this.winRef.nativeWindow['dumbleData'].page.siteSection = 'game';
      this.winRef.nativeWindow['dumbleData'].page.pageName = 'Game Launch Screen';
      this.winRef.nativeWindow['dumbleData'].page.audience = 'educator/student';
    } else if (navData.url.includes('cheereader')) {
      this.winRef.nativeWindow['dumbleData'].page.siteSection = 'cheereader';
      this.winRef.nativeWindow['dumbleData'].page.pageName = 'CHEEReader';
      this.winRef.nativeWindow['dumbleData'].page.audience = 'educator';
    } else if (navData.url.includes('resources')) {
      this.winRef.nativeWindow['dumbleData'].page.siteSection = 'resources';
      this.winRef.nativeWindow['dumbleData'].page.pageName = 'Educator Resources';
      this.winRef.nativeWindow['dumbleData'].page.audience = 'educator';
    } else if (navData.url.includes('launcher')) {
      if (this.auth.isDemoUser()) {
        this.winRef.nativeWindow['dumbleData'].page.siteSection = 'game demo';
      } else {
        this.winRef.nativeWindow['dumbleData'].page.siteSection = 'game';
      }
      this.winRef.nativeWindow['dumbleData'].page.pageName = null;
      this.winRef.nativeWindow['dumbleData'].page.audience = 'educator/student';
    } else {
      this.winRef.nativeWindow['dumbleData'].page.pageName = null;
      this.winRef.nativeWindow['dumbleData'].page.siteSection = null;
      this.winRef.nativeWindow['dumbleData'].page.audience = null;
    }

    // Triggering the dumble data update
    if (this.winRef.nativeWindow['_satellite']) {
      this.winRef.nativeWindow['_satellite'].track('page-view');
    }
  }

  ngAfterViewInit() {
    this.nativeElement = this.el.nativeElement;
    /** TODO: refactor this very hacky solution to gaining control of the SDM Nav Bar selectors */
    const intervalId = setInterval(() => {
      if ($('#sdm-nav').children().length > 1 && this.authenticatedFlag === false) {
        this.authenticatedFlag = true;
        $('#sdm-nav>#sdm-nav').siblings().remove('div.sdm-dropdown.sdm-userInfo');
        $('#sdm-nav>#sdm-nav').siblings().remove('div.sdm-dropdown.sdm-app-dropdown');
        clearInterval(intervalId);
      }
    }, 1);
    // On iOS devices, this prevents the nav dropdown from closing when clicking on a link.
    $('.sdm-dropdown a').on('touchstart', function (e) {
      e.stopPropagation();
    });
  }
}
