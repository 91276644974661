import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { YearOverYearService } from '../year-over-year/year-over-year.service';

@Injectable()
export class UtilService {
  private cohortIdTypeMap = {
    schoolsGroup: 'schoolId',
    teachers: 'teacherId',
    sections: 'sectionId',
    students: 'studentId',
    student: 'studentId'
  };

  constructor(private authService: AuthService, private yoyService: YearOverYearService) { }

  /**
    * Searches through the nav history to find a schoolId for a teacher that we're viewing
    * @returns number
    */
  getSchoolId(navHistory: any[]): number {
    const schoolHistory = navHistory.find(nav => nav.event.idType === 'schoolId');
    if (schoolHistory) {
      return schoolHistory.event.id;
    }
    return this.authService.user.schoolId;
  }

  /**
   * Helper function to update year filters
   */
  updateYearFilters(id: number, idType: string, navHistory: any[], year = 0) {
    let schoolId;
    if (idType === 'teacherId' || idType === 'studentId') {
      schoolId = this.getSchoolId(navHistory);
    }
    this.yoyService.updateYearFilters(id, idType, year, schoolId);
  }

  /**
   * Gets id type by cohort type
   * @param cohortType The cohort type
   */
  getIdTypeByCohortType(cohortType: string): string {
    return this.cohortIdTypeMap[cohortType];
  }

}
