import { EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Jwt } from '../interfaces/jwt.interface';


@Injectable()
export class AuthService {
    user: Jwt;
    jwt: string = undefined;
    sdm_nav_ctx: any = undefined;
    headers: any;
    env: any;
    authToken: any;
    authTokenUpdated: EventEmitter<any> = new EventEmitter();
    isAuthenticated = false;
    apiURL: string;
    topLevelParam: {
        id: number,
        idType: string
        role: string
    };
    navbar = {
        showGame: true,
        showReports: true,
        showCheeReader: true,
        showResources: true,
        showSupport: true,
        showMenu: true
    };
    jwtHelper = new JwtHelperService();

    constructor() {
    }

    setTopLevelParam(role) {
        this.topLevelParam = { idType: undefined, id: undefined, role };
        switch (role) {
            case 'districtAdmin':
                this.topLevelParam.idType = 'districtId';
                this.topLevelParam.id = this.user.districtId;
                break;
            case 'schoolAdmin':
                this.topLevelParam.idType = 'schoolId';
                this.topLevelParam.id = this.user.schoolId;
                break;
            case 'teacher':
                this.topLevelParam.idType = 'teacherId';
                this.topLevelParam.id = this.user.dpId;
                break;
            default:
                // student
                this.topLevelParam.idType = 'studentId';
                this.topLevelParam.id = this.user.dpId;
                break;
        }
    }

    setNavbarParam(role) {

        if (this.isDemoUser()) {
            this.navbar.showMenu = false;
        }
        switch (role) {
            case 'districtAdmin':
                this.navbar.showCheeReader = false;
                break;
            case 'schoolAdmin':
                this.navbar.showCheeReader = false;
                break;
            case 'teacher':
                break;
            case 'student':
                this.navbar.showReports = false;
                this.navbar.showCheeReader = false;
                break;
            default:
                console.log('Unknown role', role);
                break;
        }
    }

    /**
     * Determines whether user is a demo user
     */
    isDemoUser(): boolean {
        return this.user.productCode === 'OOKA_DEMO';
    }

    setAuth(jwtStr: string, sdmStr: string, authToken: string, env: string) {
        this.jwt = jwtStr;
        this.sdm_nav_ctx = sdmStr;
        this.headers = new HttpHeaders({
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + this.jwt
        });
        if (this.jwt || this.sdm_nav_ctx) {
            this.user = this.jwtHelper.decodeToken(this.jwt);
            this.isAuthenticated = true;
            this.apiURL = this.user.servicesBaseUrl;
        }
        if (this.isAuthenticated) {
            this.setTopLevelParam(this.user.dpRole);
            this.setNavbarParam(this.user.dpRole);
        }

        this.authTokenUpdated.emit({
            authToken: this.authToken = authToken,
            env: this.env = env
        });
    }

    bypassCookies() {
        /** Set environment.BYPASS_SDM_AUTH_COOKIES = true temporarily to set ooka_jwt and sdm_nav_ctx cookies which will
         * then allow the application to work for domain http://ooka-dev.scholastic.com encoded in the strings below.
         * The SDM nav bar will not appear because the oauth-nonce is a mismatch but there will be no browser JS errors.*/
        const jwtStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9' +
            '.eyJvcmlnX2lhdCI6MTUyMzMxNjAwMSwic2VydmljZXNFbnYiOiJkZXYiLCJzZXJ2aWNlc0Jhc2VVcmwiO' +
            'iJodHRwczovL3NlcnZpY2VzLm9va2EtZGV2LnNjaG9sYXN0aWMuY29tIiwiZ2FtZVVzZXJJZCI6MSwiZHB' +
            'JZCI6MzM0OTMwNSwiZHBSb2xlIjoidGVhY2hlciIsImdpdmVuTmFtZSI6Ik9va2EiLCJmYW1pbHlOYW1lI' +
            'joiRGV2Iiwic2VjdGlvbnMiOlsiMTY4NzQxIiwiMjM1NjYwIl0sImRpc3RyaWN0SWQiOjMzMTcwMzUsInN' +
            'jaG9vbElkIjozMzE3MDM0LCJhdXRoVG9rZW4iOiJkNzViMTkyMS1kNmZjLTQ2ZjYtYmJjOS0wNWJhNDZmM' +
            'zVjZDUiLCJzZXNzaW9uS2V5IjoiNDk0MGE4MzgtODk5OC00ZDRkLTg0NDktMmNjYmY5ZDljNGE3In0' +
            '.ECaQ6280eFuBRxYhi6nPa1Wb7FcIE59P774nuxqoFkE';
        const sdmStr = '%7B%20%20%20%22user_id%22%20%3A%20%223349305%22%2C%20%20%20%22name%22%20%3A%20' +
            '%22Dev%22%2C%20%20%20%22portalBaseUrl%22%20%3A%20%22https%3A%2F%2Fdigital-dev.scholastic.com' +
            '%22%2C%20%20%20%22orgId%22%20%3A%20%223317034%22%2C%20%20%20%22orgName%22%20%3A%20%22ANTIOCH%20MIDDLE' +
            '%20SCHOOL%22%2C%20%20%20%22orgType%22%20%3A%20%22school%22%2C%20%20%20%22appCodes%22%20%3A%20%5B%20%22' +
            'word%22%2C%20%22ooka%22%20%5D%2C%20%20%20%22staff%22%20%3A%20true%2C%20%20%20%22admin%22%20%3A%20false' +
            '%2C%20%20%20%22extSessionId%22%20%3A%20%22bb85bd31-e8de-4a4a-b758-bbe85af91af2%22%2C%20%20%20' +
            '%22extSessionEndpoint%22%20%3A%20%22https%3A%2F%2Fcnp0ebsm29.execute-api.us-east-1.amazonaws.com' +
            '%2Fdev1%2Fextendedsession%22%2C%20%20%20%22appCode%22%20%3A%20%22ooka%22%2C%20%20%20%22' +
            'appId%22%20%3A%20%2247%22%2C%20%20%20%22parentOrgId%22%20%3A%20%223317035%22%2C%20%20%20%22' +
            'env%22%20%3A%20%22dev1%22%2C%20%20%20%22role%22%20%3A%20%22educator%22%2C%20%20%20%22iamUserId' +
            '%22%20%3A%20%2298186632%22%20%7D';
        // {   "user_id" : "3349305",   "name" : "Dev",
        // "portalBaseUrl" : "https://digital-dev.scholastic.com",
        // "orgId" : "3317034",   "orgName" : "ANTIOCH MIDDLE SCHOOL",
        // "orgType" : "school",   "appCodes" : [ "word", "ooka" ],
        // "staff" : true,   "admin" : false,
        // "extSessionId" : "bb85bd31-e8de-4a4a-b758-bbe85af91af2",
        // "extSessionEndpoint" : "https://cnp0ebsm29.execute-api.us-east-1.amazonaws.com/dev1/extendedsession",
        // "appCode" : "ooka",   "appId" : "47",   "parentOrgId" : "3317035",
        // "env" : "dev1",   "role" : "educator",   "iamUserId" : "98186632" }
        // const orgAsTeacher ='%7B%22teacherId%22%3A%223349305%22%2C%22orgId%22%3A%223317034%22%7D';
        const authToken = this.jwtHelper.decodeToken(jwtStr).authToken;
        const env = this.jwtHelper.decodeToken(jwtStr).servicesEnv;
        this.setAuth(
            jwtStr,
            sdmStr,
            authToken,
            env,
        );
    }

    /**
   * Gets the sdm base url based on env
   * @param env Environment to get SDM base url for
   */
    getSDMBaseURLByEnv(env: string): string {
        const sdmBaseURLMap = {
            dev: 'https://digital-stage.scholastic.com',
            qa: 'https://digital-qa.scholastic.com',
            perf: 'https://digital-perf.scholastic.com',
            prod: 'https://digital.scholastic.com'
        };
        return sdmBaseURLMap[env] || sdmBaseURLMap['prod'];
    }

}
