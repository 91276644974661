import { Injectable } from '@angular/core';
import { ErrorService } from '../error-service/error-service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ResolutionService {
  private errHeader = 'Can\'t see reports?';
  private errMsg = `If you're using a tablet, hold your device in landscape mode to view reports.
  If using a computer, expand your browser window or adjust your screen resolution to 1024 or higher.`;
  private errMsgLine2 = `If you have any questions or concerns, please contact Customer Service at
  1-800-724-2222 or email digitalservice@scholastic.com`;
  lastLocation;
  private windowSizeBehaviorSubject = new BehaviorSubject<Number>(0);

  constructor(private error: ErrorService, private router: Router, private modal: NgbModal) { }

  /*
    Shows the resolution error page
    @returns void
  */
  showErrorMessage(reports?: boolean): void {
    this.lastLocation = reports ? 'educator/reports' : this.router.url;
    this.error.setErrorMessage(this.errHeader, this.errMsg, this.errMsgLine2, true);
    this.modal.dismissAll();
  }

  /*
    Goes to last location before showing error message if exists
    @returns void
  */
  gotoLastLocation(): void {
    if (this.lastLocation) {
      this.router.navigateByUrl(this.lastLocation);
      this.lastLocation = null;
    } else {
      this.router.navigate(['educator', 'reports']);
    }
  }

  /**
   * This function will broadcast the changes made whenever
   * window resize is detected
   * @param size The window size
   */
  windowSize(size: Number): void {
    this.windowSizeBehaviorSubject.next(size);
  }

  /**
   * This function will notify listeners that the change is happening
   * @returns Observable
   */
  broadcastWindowSize(): BehaviorSubject<Number> {
    return this.windowSizeBehaviorSubject;
  }
}
