import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
/** app dependencies */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DeviceDetectorService } from 'ngx-device-detector';
/** app components, services etc. **/
import { AppComponent } from './app.component';
import { ApiService } from './api/api.service';
import { AuthService } from './auth/auth.service';
import { CookieReaderComponent } from './cookie-reader/cookie-reader.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { SdmNavBarComponent } from './sdm-nav-bar/sdm-nav-bar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { GameLauncherComponent } from './game-launcher/game-launcher.component';
// import {APP_BASE_HREF} from '@angular/common';
/** app reports **/
import { WindowRefService } from './windows-ref/window-ref.service';
import { SchoolYtdDropDownComponent } from './school-ytd-drop-down/school-ytd-drop-down/school-ytd-drop-down.component';
import { DataService } from './data/data.service';
import { TableColumnOrderPipe } from './pipes/table-column-order.pipe';
import { AuthGuard } from './guards/auth.guard';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { LandingComponent } from './landing/landing.component';
import { ToolsResourcesComponent } from './tools-resources/tools-resources.component';
import { ErrorComponent } from './error/error.component';
import { ErrorService } from './error-service/error-service';
import { ResolutionService } from './resolution/resolution.service';
import { GameLauncherGuard } from './guards/game-launcher.guard';
import { ProficiencyLevelsService } from './reports/proficiency-levels/proficiency-levels.service';
import { DoubleClickService } from './double-click-service/double-click.service';
import { cookieRedirectInitializer } from './initializers/cookie-redirect-initializer';
import { ClientContextService } from './client-context/client-context.service';
import { UtilService } from './util/util.service';
import { YearOverYearService } from './year-over-year/year-over-year.service';
import { ShingleComponent } from './shingle/shingle.component';
import { SharedModule } from './shared/shared.module';
import { EducatorLoadingGuard } from './guards/educator-loading.guard';
import { DemoLoadingGuard } from './guards/demo-loading.guard';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    CollapseModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AuthService,
    ErrorService,
    ApiService,
    DataService,
    WindowRefService,
    AuthGuard,
    EducatorLoadingGuard,
    DemoLoadingGuard,
    ResolutionService,
    GameLauncherGuard,
    ProficiencyLevelsService,
    DoubleClickService,
    {
      provide: APP_INITIALIZER,
      useFactory: cookieRedirectInitializer,
      multi: true,
      deps: [CookieService, AuthService, WindowRefService]
    },
    ClientContextService,
    YearOverYearService,
    UtilService,
    DeviceDetectorService
  ],
  declarations: [
    AppComponent,
    CookieReaderComponent,
    NavbarComponent,
    GameLauncherComponent,
    SchoolYtdDropDownComponent,
    TableColumnOrderPipe,
    AppFooterComponent,
    LandingComponent,
    ToolsResourcesComponent,
    ErrorComponent,
    ShingleComponent,
    SdmNavBarComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {}
