import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ResolutionService } from '../resolution/resolution.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private resolution: ResolutionService) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const path = next.url.length > 0 ? next.url[0].path : '';
    const role = this.auth.topLevelParam.role;
    if (this.auth.isDemoUser()) {
      this.router.navigate(['demo']);
      return true;
    } else {
      if (path === 'reports') {
        if (window.innerWidth < 1024) {
          this.resolution.showErrorMessage(true);
        }
        switch (role) {
          case 'districtAdmin':
            return true;
          case 'schoolAdmin':
            return true;
          case 'teacher':
            return true;
          default:
            // student
            this.router.navigate(['']);
            return false;
        }
      } else if (path === 'cheereader') {
        if (role === 'teacher') {
          return true;
        }
      } else if (path === '' || path === 'landing') {
        switch (role) {
          case 'student':
            return true;
          default:
            // everyone else
            this.router.navigate(['educator', 'reports']);
            return false;
        }
      }
      return false;
    }
  }
}
