import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-ytd-drop-down',
  templateUrl: './school-ytd-drop-down.component.html',
  styleUrls: ['./school-ytd-drop-down.component.scss']
})
export class SchoolYtdDropDownComponent implements OnInit {

    /**
     * School YTD Drop Down selects the something or the other.
     */
    constructor() { }

  ngOnInit() {
  }

}
