import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ClientContextService } from '../client-context/client-context.service';

@Injectable()
export class YearOverYearService {

  constructor(private apiService: ApiService, private clientContextService: ClientContextService) { }

  /**
   * Updates the reports year filters
   * @param id id of cohort
   * @param type id type of cohort
   * @param schoolId school id associated with teachers and students
   */
  updateYearFilters(id: any, type: string, year: number = 0, schoolId?: any) {
    const options = { [type]: id };
    if (schoolId !== undefined) {
      options['schoolId'] = schoolId;
    }
    this.apiService.get('/roster/v1/school_years/', options)
    .subscribe(data => {
      this.clientContextService.schoolYearFilters.next({
        filters: data.filters.year,
        year
      });
    });
  }
}
