
import {filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, PRIMARY_OUTLET, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit, OnDestroy {
  hideFooter = false;
  subscription: Subscription;
  year = 0;

  constructor(private router: Router) { }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
      const parsedUrl = this.router.parseUrl(event.url);
      const path = parsedUrl.root.hasChildren() && parsedUrl.root.children[PRIMARY_OUTLET].segments.map(segment => segment.path).join('/');
      this.hideFooter = path && path.includes('teacher_demo');
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
