import {Component, OnInit, HostListener} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ResolutionService} from './resolution/resolution.service';
import {WindowRefService} from './windows-ref/window-ref.service';

import { Subscription } from 'rxjs';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-ooka',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLoadingIndicator = false;
  loadingCount = 0;
  subscription: Subscription;

  /* TODO: Unit Test */
  public constructor(
    private titleService: Title,
    private resolutionService: ResolutionService,
    private winRef: WindowRefService,
    private router: Router
  ) {
    // First initial window size
    this.updateWindowSize();
  }

  /**
   * Event listener that gets called whenever window resizes
   */
  @HostListener('window:resize')
  onAppResize() {
    this.updateWindowSize();
  }

  public setTitle (newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  /**
   * Function notifies and updates clientContextService of new window size
   */
  public updateWindowSize() {
    this.resolutionService.windowSize(this.winRef.nativeWindow.innerWidth);
  }

  ngOnInit() {
    this.setTitle('Ooka Island Adventure');

    this.subscription = this.router.events.subscribe(this.routerEventListener.bind(this));
  }

  /**
   * The event handler for router events
   * @param event Router event object
   */
  routerEventListener(event: RouterEvent) {
    if (event instanceof RouteConfigLoadStart && !this.router.url.startsWith('/launcher')) {
      this.loadingCount++;
    } else if (event instanceof RouteConfigLoadEnd && !this.router.url.startsWith('/launcher')) {
      this.loadingCount--;
    }
    this.showLoadingIndicator = this.loadingCount > 0;
  }
}
