<div #sdmElement id="sdm-nav">
    <div id="sdm-nav" >
        <div class="sdm-dropdown sdm-userInfo"><!-- TODO: sdm-username needs to be dynamic -->
            <button class="sdm-dropdown-btn"><i class="fa fa-user-circle-o widget-glyphs usericon-glyph"
                                                aria-hidden="true"></i><span class="sdm-username">{{name}}</span><i
                class="fa fa-angle-down widget-glyphs chevron-glyph" aria-hidden="true"></i></button>
            <div class="sdm-dropdown-content sdm-app-dropdown-content sdm-logout-link arrow_sdm">
                <a class="ooka-account-link"><span class="ooka-account-text">{{fullName}}'s Account</span></a>
                <br>
                <div class="sdm-apps">
                    <div *ngFor="let appCode of appArray">
                        <a href="{{appCode.url}}" ><img
                            class="sdm-app-images" src="//digital.scholastic.com/media/applications/widget_thumbnails/{{appCode.code}}.png"></a>
                    </div>
                </div>
                <div class="sdm-dropdown-separator"></div>
                <a id="sdm-portal-link">
                    <svg class="sdm-home-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.47 35.59">
                        <defs>
                            <style>.cls-1{fill:none;stroke:#a9adb2;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style>
                        </defs>
                        <title>SDM Home</title>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path class="cls-1" d="M31.45,19v14.3c0,.9-1.22,1.27-2.12,1.27H6.23c-.9,0-2.33-.37-2.33-1.27V19.65"/>
                                <path class="cls-1"
                                      d="M34.94,16.92,19.54,1.53a1.88,1.88,0,0,0-2.62,0L1.53,16.92a1.83,1.83,0,0,0,2.58,2.58L18.23,5.39,32.35,19.51a1.83,1.83,0,0,0,2.58-2.58Z"/>
                                <path class="cls-1" d="M26.86,8.54V6c0-.9.81-1.24,1.71-1.24h1.06c.9,0,1.83.34,1.83,1.24v6.78"/>
                                <polyline class="cls-1" points="14.21 34.36 14.21 21.02 22.25 21.02 22.25 34.36"/>
                            </g>
                        </g>
                    </svg>
                    <span class="sdm-dashboard-text">Scholastic Digital Manager</span></a>
                <a id="sdm-logout"(click)="onLogout()"><i class="fa fa-sign-out logout-glyph" aria-hidden="true"></i>
                    <svg class="sdm-logout-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.67 25.2">
                        <defs>
                            <style>.cls-1{fill:none;stroke:#a9adb2;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style>
                        </defs>
                        <title>Log Out</title>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <polyline class="cls-1" points="20.26 16.63 20.26 24.2 1 24.2 1 1 20.26 1 20.26 8.63"/>
                                <line class="cls-1" x1="12.26" y1="12.61" x2="27.6" y2="12.61"/>
                                <polyline class="cls-1" points="24.43 8.37 28.67 12.61 24.43 16.85"/>
                            </g>
                        </g>
                    </svg>
                    <span class="sdm-logout-text">Log out</span></a>
            </div>
        </div>

    </div>
</div>
