import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ErrorService } from '../error-service/error-service';
import { ResolutionService } from '../resolution/resolution.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorHeader = 'Something went wrong.';
  @Input() errorMessage = `Unfortunately we aren\'t able to generate the requested report at this time.
  Please contact Customer Service at 1-800-724-2222 or email digitalservice@scholastic.com if you continue to experience this issue.`;
  errorMessageLine2 = '';

  @HostListener('window:resize') onResize() {
    if (window.innerWidth >= 1024 && this.route.snapshot.queryParamMap.get('return') === 'reports') {
      this.resolution.gotoLastLocation();
    }
  }

  constructor(public errorService: ErrorService, private resolution: ResolutionService, private route: ActivatedRoute) {
  }

  ngOnInit() {
  }
}
