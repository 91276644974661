<div class="resource-header-box">
    <span class="resource-header-text">Educator Resources</span>
</div>
<img *ngIf="isLoading && !errorLoadingData" src="../../assets/img/loading_blue.gif">
<app-error *ngIf="errorLoadingData" [errorMessage]="errorMessage"></app-error>
<div *ngIf="!isLoading" class="container resource-table-section">
    <div *ngFor="let heading of settingsJSON.rows.resourceHeadings">
        <div class="row">
            <div class="col-12">
                <div class="section-col">
                    <div class="resource-title-container">
                        <div class="resource-title">
                            {{heading.name}}
                        </div>
                    </div>
                    <div class="blue-line-break-res"></div>
                    <div class="resource-description">
                        {{heading.description}}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="row resource-row">
                <div class="columns resource-col">
                    <div class="large-block-grid-3">
                        <div *ngFor="let resource of heading.resources" class="single-resource">
                            <a target="_blank" href="{{resource.target}}">
                                <img [src]="_DomSanitizationService.bypassSecurityTrustUrl(resource.imageSrc)"
                                    alt="{{resource.title}}" class="resource-img" />
                                <div class="single-resource-body">
                                    <div class="single-resource-title">
                                        {{resource.title}}
                                    </div>
                                    <div class="single-resource-text">{{resource.subtitle}}</div>
                                </div>
                            </a>
                            <div *ngFor="let section of resource.sections" class="single-resource">
                                <a target="_blank" href="{{section.target}}">
                                    <div class="single-resource-body">
                                        <div class="single-resource-title">
                                            {{section.title}}
                                        </div>
                                        <div class="single-resource-text">{{section.subtitle}}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>