
import {filter} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, PRIMARY_OUTLET, NavigationEnd, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class NavbarComponent implements OnInit, OnDestroy {
    gameLaunchParams: string;
    apiURL: string;
    showMenu = true;
    showLogo = true;
    showDemoMenu = false;
    isGame = false;
    subscription: Subscription;

    constructor(private auth: AuthService, private router: Router) { }

    ngOnInit() {
        this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
            this.showLogo = event.url !== '/demo';
            const parsedUrl = this.router.parseUrl(event.url);
            const path = parsedUrl.root.hasChildren()
                && parsedUrl.root.children[PRIMARY_OUTLET].segments.map(segment => segment.path).join('/');
            this.showDemoMenu = path && (path === 'launcher' || path.includes('teacher_demo') || path === 'error')
                && this.auth.isDemoUser();
            this.isGame = path === 'launcher';
        });
        this.auth.authTokenUpdated.subscribe(
            (data) => {
                if (this.auth.navbar) {
                    setTimeout(() => {
                        this.showMenu = this.auth.navbar.showMenu;
                    }, 0);
                }
                this.apiURL = this.auth.apiURL;
                this.gameLaunchParams = '?token=' + data.authToken + '&env=' + data.env;
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
