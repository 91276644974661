<app-cookie-reader></app-cookie-reader>
<app-navbar></app-navbar>
<main>
    <div *ngIf="showLoadingIndicator" class="loading-indicator">
        <div class="loading-div">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw spinner-color"></i>
        </div>
    </div>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</main>