
import { throwError, ReplaySubject, Observable, Subject, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error-service/error-service';



@Injectable()
export class ApiService {
    // implement RxJS shareReplay to prevent multiple same requests getDistrictTimePlayedByStaffId
    public activeRequest: ReplaySubject<any> = new ReplaySubject(1);

    apiURL: string = undefined;

    tooManyStudentsHttpStatus = 414;
    showErrorSubject = new Subject();

    urlPath = {
        REPORT_NAV_METRICS: '/web/reports/v1/report-nav-metrics',
        OVERVIEW_NAV_TABLE: '/web/reports/v1/overview/table-nav',
        OVERVIEW_GRADE_TABLE_USAGE: '/web/reports/v1/overview/grade-table',
        OVERVIEW_GRADE_INFOGRAPHIC_USAGE: '/web/reports/v1/overview/infographic',
        OVERVIEW_CHART_TIME_PLAYED: '/web/reports/v1/time-played/period-location',
        OVERVIEW_CHART_PHONOLOGICAL_SCORES: '/web/reports/v1/phonological/scores',
        OVERVIEW_CHART_COMPREHENSION_SCORES: '/web/reports/v1/comprehension/scores',
        TIME_PLAYED_NAV_TABLE: '/web/reports/v2/time-played/table-nav',
        OVERALL_PERFORMANCE_TABLE: '/web/reports/v1/overall-performance',
        PHONOLOGICAL_TABLE_NAV: '/web/reports/v2/phonological/table-nav',
        PHONOLOGICAL_CHART_SCORES: '/web/reports/v1/phonological/scores',
        PHONOLOGICAL_CHART_DISTRIBUTION: '/web/reports/v1/phonological/distribution',
        PHONOLOGICAL_CHART_DISTRIBUTION_DETAILS: '/web/reports/v1/distributionDetails/',
        PHONOLOGICAL_CHART_PROGRESS: '/web/reports/v1/phonological/progress',
        COMPREHENSION_TABLE_NAV: '/web/reports/v2/comprehension/table-nav',
        COMPREHENSION_SCORES: '/web/reports/v1/comprehension/scores',
        COMPREHENSION_DISTRIBUTION: '/web/reports/v1/comprehension/distribution',
        COMPREHENSION_PROGRESS: '/web/reports/v1/comprehension/progress',
        CHEEREADER_STUDENTS: '/web/v1/staff/students/',
        CHEEREADER_MESSAGES: '/web/v1/cheereader/messages/',
        CHEEREADER_STUDENT_MESSAGES: '/web/v1/cheereader/student-messages/',
    };

    handleError(error: HttpErrorResponse) {
        if (error.status === this.tooManyStudentsHttpStatus) {
            this.showErrorSubject.next(true);
            return EMPTY;
        } else if (error.status === 413) {
            this.errorService.setErrorMessage('F.I.R.S.T. is unable to display classes with more than 60 students.',
                `We're unable to display the class reports at this time, as one or more classes have more than 60 students.`,
                `Please contact Scholastic F.I.R.S.T. customer support at 1-800-724-2222, option 5, or email us at
            digitalservice@scholastic.com for assistance in reducing class sizes.`);
        } else if (error.status !== 200) {
            this.errorService.setErrorMessage('This page cannot be displayed at this time.');
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private errorService: ErrorService
    ) {
        this.apiURL = (auth && auth.user && auth.user.servicesBaseUrl) ? auth.user.servicesBaseUrl : null;
    }


    // TODO: refactor to include all 4 GET requests after GET api service testing is successful

    public serialize(options: any): string {
        let params: HttpParams = new HttpParams();
        Object.keys(options).forEach((param) => {
            if (options.hasOwnProperty(param)) {
                const value = options[param];
                params = params.set(param, value);
            }
        });
        return params.toString();
    }

    public get(path: string, options: any): Observable<any> {
        let url = this.apiURL + path;
        if (!!options) {
            url += '?' + this.serialize(options);
        }
        return this.http
            .get(url, { headers: this.auth.headers }).pipe(
            catchError(e => {
                e.errorService = this.errorService;
                return throwError(this.handleError(e));
            }));
    }

    public post(path: string, body: any, options: any): Observable<any> {
        let url = this.apiURL + path;
        if (!!options) {
            url += '?' + this.serialize(options);
        }
        return this.http
            .post(url, body, { headers: this.auth.headers, observe: 'response'})
            .pipe(catchError(this.handleError));
    }

}

