<nav class="navbar" [ngClass]="{ 'no-border-bottom': !showLogo, 'is-game': isGame }">
    <!-- inline style fix for Firefox Edge navbar-brand irregularity -->
    <img *ngIf="showLogo"
        class="nav-item-1"
        [src]="auth.topLevelParam?.role === 'student' ? '../../assets/img/Ooka-Logo-2018.svg' : '../../assets/img/ScholasticFIRST_HeaderLogo.png'"
        [alt]="auth.topLevelParam?.role === 'student' ? 'Ooka Island Adventure' : 'Scholastic F.I.R.S.T.'" />
    <div class="btn-group nav-item-2" dropdown #dropdown="bs-dropdown">
        <div class="blank-hamburger" *ngIf="!showMenu && !showDemoMenu">

        </div>
        <button *ngIf="showMenu || showDemoMenu" dropdownToggle type="button" class="navbar-toggler navbar-toggler-border" data-toggle="collapse"
            data-target="#navbarNav">
            <span class="navbar-toggler-icon navbar-toggler-white"></span>
        </button>
        <ul role="menu" *dropdownMenu class="dropdown-menu arrow_top dropdown-menu-adjusted" (click)="dropdown.hide()">
            <ng-container *ngIf="showMenu">
                <!-- TODO: create svg include directive -->
                <li role="menuitem" *ngIf="auth.navbar.showGame">
                    <!-- Do not make this link a routerLink. It needs to do a page load. -->
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" href="{{apiURL}}/launcher{{gameLaunchParams}}"
                        routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.9 34.32" class="dropdown-menu-img">
                                <title>Game_1</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <line class="cls-1" x1="7.98" y1="33.32" x2="21.93" y2="33.32" />
                                        <line class="cls-1" x1="14.95" y1="26.55" x2="14.95" y2="33.21" />
                                        <path class="cls-1" d="M22.9,11.64V19.1a7.47,7.47,0,0,1-7.45,7.45h-.67A7.47,7.47,0,0,1,7.33,19.1V11.64Z" />
                                        <path class="cls-1" d="M23.09,14.92H28.9s.21,8.3-7.08,8.09" />
                                        <path class="cls-1" d="M6.81,14.92H1S.79,23.22,8.08,23" />
                                        <line class="cls-1" x1="14.76" y1="5.28" x2="14.76" y2="1" />
                                        <line class="cls-1" x1="20.46" y1="7.82" x2="23.45" y2="4.83" />
                                        <line class="cls-1" x1="10.03" y1="7.18" x2="7.04" y2="4.2" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Ooka Island Program
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showReports">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" [routerLink]="['educator', 'reports']" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 31.24" class="dropdown-menu-img">
                                <title>Reports</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <rect class="cls-1" x="1.1" y="1" width="31.9" height="29.24" rx="2.76" ry="2.76" />
                                        <line class="cls-1" x1="27.84" y1="10.04" x2="32.58" y2="15.58" />
                                        <line class="cls-1" x1="16.74" y1="20.27" x2="25.08" y2="9.52" />
                                        <line class="cls-1" x1="11.43" y1="17.56" x2="13.93" y2="20.91" />
                                        <line class="cls-1" x1="1" y1="24.26" x2="8.31" y2="16.63" />
                                        <circle class="cls-1" cx="10.27" cy="15.46" r="1.74" />
                                        <circle class="cls-1" cx="15.59" cy="22.27" r="1.74" />
                                        <circle class="cls-1" cx="26.8" cy="8.06" r="1.74" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Student Reports
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showCheeReader">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" [routerLink]="['educator', 'cheereader']" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.02 34.02" class="dropdown-menu-img">
                                <title>CheeReader</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1" d="M31.61,13.77l-3-.61a11.85,11.85,0,0,0-.49-1.29l1.76-2.61a2,2,0,0,0-.24-2.52L27.76,4.81a2,2,0,0,0-2.52-.25L22.64,6.3a12.09,12.09,0,0,0-1.29-.54l-.61-3.11a2,2,0,0,0-2-1.65H16a2,2,0,0,0-2,1.65l-.61,3.09a12.16,12.16,0,0,0-1.29.55L9.57,4.56A2,2,0,0,0,7,4.81L5.12,6.74a2,2,0,0,0-.25,2.53l1.74,2.61a12,12,0,0,0-.54,1.29l-3.27.61a2.19,2.19,0,0,0-1.81,2v2.72a2.19,2.19,0,0,0,1.81,2L6,21a12.4,12.4,0,0,0,.59,1.29L4.85,24.93a2,2,0,0,0,.26,2.52L7,29.38a2,2,0,0,0,2.53.25l2.61-1.74a11.81,11.81,0,0,0,1.29.54l.61,3A2,2,0,0,0,16,33h2.72a2,2,0,0,0,2-1.57l.62-3a12,12,0,0,0,1.29-.53l2.61,1.74a2,2,0,0,0,2.52-.25l1.93-1.92a2,2,0,0,0,.25-2.52l-1.74-2.61A11.9,11.9,0,0,0,28.74,21l2.87-.61a1.84,1.84,0,0,0,1.41-2V15.73A1.84,1.84,0,0,0,31.61,13.77Zm-14.2-3.68a7,7,0,1,0,7,7A7,7,0,0,0,17.41,10.09Zm0,3a4,4,0,1,0,4,4A4,4,0,0,0,17.41,13.09Z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        CheeReader
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showResources">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" routerLink="resources" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.26 25.2" class="dropdown-menu-img">
                                <title>Resources</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <rect class="cls-1" x="1" y="1" width="19.26" height="23.2" />
                                        <line class="cls-1" x1="5" y1="6.43" x2="5.86" y2="6.43" />
                                        <line class="cls-1" x1="9.53" y1="6.43" x2="16.27" y2="6.43" />
                                        <line class="cls-1" x1="5" y1="18.8" x2="5.86" y2="18.8" />
                                        <line class="cls-1" x1="9.53" y1="18.8" x2="16.27" y2="18.8" />
                                        <line class="cls-1" x1="5" y1="12.62" x2="5.86" y2="12.62" />
                                        <line class="cls-1" x1="9.53" y1="12.62" x2="16.27" y2="12.62" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Resources
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showSupport">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" target="_new" href="https://help.digital.scholastic.com/hc/en-us"
                        routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 44.31" class="dropdown-menu-img">
                                <title>Support_1</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1" d="M15.07,33.06c-.55-1.19-1.21-3.93-1.85-5.09-1.44-2.57-2.92-5.22-2.92-7.46a9.9,9.9,0,1,1,19.8,0c0,2.22-1.48,4.89-2.92,7.47-.64,1.16-1.3,3.9-1.84,5.08M19,14.23a5.5,5.5,0,0,0-5.5,5.5" />
                                        <path class="cls-1" d="M24.68,40.35l-1.9,1.9a3.66,3.66,0,0,1-5.16,0l-1.9-1.9" />
                                        <path class="cls-1" d="M15.25,33.58h10a.89.89,0,0,1,.89.89v.91a1.11,1.11,0,0,1-1.11,1.11H15.32a.8.8,0,0,1-.8-.8V34.31A.73.73,0,0,1,15.25,33.58Z" />
                                        <path class="cls-1" d="M15.15,36.49H25.38a.78.78,0,0,1,.78.78v.91A1.22,1.22,0,0,1,25,39.4H15.32a.8.8,0,0,1-.8-.8V37.12A.63.63,0,0,1,15.15,36.49Z" />
                                        <line class="cls-1" x1="29.5" y1="9.25" x2="33.5" y2="5.25" />
                                        <line class="cls-1" x1="20" y1="7" x2="20" y2="1.25" />
                                        <path class="cls-1" d="M10.5,10" />
                                        <line class="cls-1" x1="33.5" y1="18.5" x2="39.25" y2="17.5" />
                                        <line class="cls-1" x1="11" y1="9.25" x2="7" y2="5.25" />
                                        <line class="cls-1" x1="7" y1="18.5" x2="1.25" y2="17.5" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Support
                    </a>
                </li>
                <!--
                <li class="divider dropdown-divider"></li>

                <li role="menuitem"><a class="dropdown-item dropdown-menu-font" href="#">Feedback</a></li>
    -->
            </ng-container>
            <ng-container *ngIf="showDemoMenu">
                <li role="menuitem">
                    <!-- Do not make this link a routerLink. It needs to do a page load. -->
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" href="{{apiURL}}/launcher{{gameLaunchParams}}"
                        routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.9 34.32" class="dropdown-menu-img">
                                <title>Game_1</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <line class="cls-1" x1="7.98" y1="33.32" x2="21.93" y2="33.32" />
                                        <line class="cls-1" x1="14.95" y1="26.55" x2="14.95" y2="33.21" />
                                        <path class="cls-1" d="M22.9,11.64V19.1a7.47,7.47,0,0,1-7.45,7.45h-.67A7.47,7.47,0,0,1,7.33,19.1V11.64Z" />
                                        <path class="cls-1" d="M23.09,14.92H28.9s.21,8.3-7.08,8.09" />
                                        <path class="cls-1" d="M6.81,14.92H1S.79,23.22,8.08,23" />
                                        <line class="cls-1" x1="14.76" y1="5.28" x2="14.76" y2="1" />
                                        <line class="cls-1" x1="20.46" y1="7.82" x2="23.45" y2="4.83" />
                                        <line class="cls-1" x1="10.03" y1="7.18" x2="7.04" y2="4.2" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Student Experience
                    </a>
                </li>

                <li role="menuitem">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" [routerLink]="['demo', 'teacher_demo', 'overview']" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 31.24" class="dropdown-menu-img">
                                <title>Reports</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <rect class="cls-1" x="1.1" y="1" width="31.9" height="29.24" rx="2.76" ry="2.76" />
                                        <line class="cls-1" x1="27.84" y1="10.04" x2="32.58" y2="15.58" />
                                        <line class="cls-1" x1="16.74" y1="20.27" x2="25.08" y2="9.52" />
                                        <line class="cls-1" x1="11.43" y1="17.56" x2="13.93" y2="20.91" />
                                        <line class="cls-1" x1="1" y1="24.26" x2="8.31" y2="16.63" />
                                        <circle class="cls-1" cx="10.27" cy="15.46" r="1.74" />
                                        <circle class="cls-1" cx="15.59" cy="22.27" r="1.74" />
                                        <circle class="cls-1" cx="26.8" cy="8.06" r="1.74" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Educator Experience
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showCheeReader">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" [routerLink]="['demo', 'teacher_demo', 'cheer']" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.02 34.02" class="dropdown-menu-img">
                                <title>CheeReader</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1" d="M31.61,13.77l-3-.61a11.85,11.85,0,0,0-.49-1.29l1.76-2.61a2,2,0,0,0-.24-2.52L27.76,4.81a2,2,0,0,0-2.52-.25L22.64,6.3a12.09,12.09,0,0,0-1.29-.54l-.61-3.11a2,2,0,0,0-2-1.65H16a2,2,0,0,0-2,1.65l-.61,3.09a12.16,12.16,0,0,0-1.29.55L9.57,4.56A2,2,0,0,0,7,4.81L5.12,6.74a2,2,0,0,0-.25,2.53l1.74,2.61a12,12,0,0,0-.54,1.29l-3.27.61a2.19,2.19,0,0,0-1.81,2v2.72a2.19,2.19,0,0,0,1.81,2L6,21a12.4,12.4,0,0,0,.59,1.29L4.85,24.93a2,2,0,0,0,.26,2.52L7,29.38a2,2,0,0,0,2.53.25l2.61-1.74a11.81,11.81,0,0,0,1.29.54l.61,3A2,2,0,0,0,16,33h2.72a2,2,0,0,0,2-1.57l.62-3a12,12,0,0,0,1.29-.53l2.61,1.74a2,2,0,0,0,2.52-.25l1.93-1.92a2,2,0,0,0,.25-2.52l-1.74-2.61A11.9,11.9,0,0,0,28.74,21l2.87-.61a1.84,1.84,0,0,0,1.41-2V15.73A1.84,1.84,0,0,0,31.61,13.77Zm-14.2-3.68a7,7,0,1,0,7,7A7,7,0,0,0,17.41,10.09Zm0,3a4,4,0,1,0,4,4A4,4,0,0,0,17.41,13.09Z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        CheeReader
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showResources">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" [routerLink]="['demo', 'teacher_demo', 'resources']" routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.26 25.2" class="dropdown-menu-img">
                                <title>Resources</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <rect class="cls-1" x="1" y="1" width="19.26" height="23.2" />
                                        <line class="cls-1" x1="5" y1="6.43" x2="5.86" y2="6.43" />
                                        <line class="cls-1" x1="9.53" y1="6.43" x2="16.27" y2="6.43" />
                                        <line class="cls-1" x1="5" y1="18.8" x2="5.86" y2="18.8" />
                                        <line class="cls-1" x1="9.53" y1="18.8" x2="16.27" y2="18.8" />
                                        <line class="cls-1" x1="5" y1="12.62" x2="5.86" y2="12.62" />
                                        <line class="cls-1" x1="9.53" y1="12.62" x2="16.27" y2="12.62" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Resources
                    </a>
                </li>

                <li role="menuitem" *ngIf="auth.navbar.showSupport">
                    <a class="dropdown-item dropdown-menu-font dropdown-menu-img" target="_new" href="https://help.digital.scholastic.com/hc/en-us"
                        routerLinkActive="active-link">
                        <div class="menuitem-img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 44.31" class="dropdown-menu-img">
                                <title>Support_1</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-1" d="M15.07,33.06c-.55-1.19-1.21-3.93-1.85-5.09-1.44-2.57-2.92-5.22-2.92-7.46a9.9,9.9,0,1,1,19.8,0c0,2.22-1.48,4.89-2.92,7.47-.64,1.16-1.3,3.9-1.84,5.08M19,14.23a5.5,5.5,0,0,0-5.5,5.5" />
                                        <path class="cls-1" d="M24.68,40.35l-1.9,1.9a3.66,3.66,0,0,1-5.16,0l-1.9-1.9" />
                                        <path class="cls-1" d="M15.25,33.58h10a.89.89,0,0,1,.89.89v.91a1.11,1.11,0,0,1-1.11,1.11H15.32a.8.8,0,0,1-.8-.8V34.31A.73.73,0,0,1,15.25,33.58Z" />
                                        <path class="cls-1" d="M15.15,36.49H25.38a.78.78,0,0,1,.78.78v.91A1.22,1.22,0,0,1,25,39.4H15.32a.8.8,0,0,1-.8-.8V37.12A.63.63,0,0,1,15.15,36.49Z" />
                                        <line class="cls-1" x1="29.5" y1="9.25" x2="33.5" y2="5.25" />
                                        <line class="cls-1" x1="20" y1="7" x2="20" y2="1.25" />
                                        <path class="cls-1" d="M10.5,10" />
                                        <line class="cls-1" x1="33.5" y1="18.5" x2="39.25" y2="17.5" />
                                        <line class="cls-1" x1="11" y1="9.25" x2="7" y2="5.25" />
                                        <line class="cls-1" x1="7" y1="18.5" x2="1.25" y2="17.5" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        Support
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
    <app-sdm-nav-bar></app-sdm-nav-bar>
</nav>
