import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameLauncherComponent } from '../game-launcher/game-launcher.component';
import { AuthGuard } from '../guards/auth.guard';
import { LandingComponent } from '../landing/landing.component';
import { ToolsResourcesComponent } from '../tools-resources/tools-resources.component';
import { ErrorComponent } from '../error/error.component';
import { GameLauncherGuard } from '../guards/game-launcher.guard';
import { EducatorLoadingGuard } from '../guards/educator-loading.guard';
import { DemoLoadingGuard } from '../guards/demo-loading.guard';

export const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  {
    path: 'launcher',
    component: GameLauncherComponent,
    data: { title: 'Game Launcher' },
    canDeactivate: [GameLauncherGuard]
  },
  {
    path: 'educator',
    loadChildren: () => import('../educator/educator.module').then((m) => m.EducatorModule),
    canLoad: [EducatorLoadingGuard]
  },
  { path: 'resources', component: ToolsResourcesComponent },
  {
    path: 'demo',
    loadChildren: () => import('../demo/demo.module').then((m) => m.DemoModule),
    canLoad: [DemoLoadingGuard]
  },
  { path: 'landing', component: LandingComponent, canActivate: [AuthGuard] },
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
/**
* TODO: successful log in first page should be something not blank
* TODO: PageNotFound page routing
* TODO: Roles and Permissions based routing (possibly Route Guards Service combination)
* TODO: Clicking on Student Reports should take user to last report or overview
* TODO: Clicking on Report Nav button should show top level user has permission to or should show last level viewed?
* TODO: SDM navigation refactor: inactivity is default and activity sensing may not be connected
* TODO: SDM navigation refactor: will need authorization indicator if we want to prevent unauthenticated users.
* */
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
