import { Injectable } from '@angular/core';
@Injectable()
export class DoubleClickService {
    private selected = false;
    private selectedTimeout: any;
    private lastClicked = '';
    private doubleClickTime = 250;

    constructor() { }

    /**
     * Determines whether a specific element was double clicked
     * @param uniqueId Unique identifier for element clicked
     */
    doubleClicked(uniqueId: any) {
        if (uniqueId !== this.lastClicked) {
            clearTimeout(this.selectedTimeout);
            this.selected = true;
            this.lastClicked = uniqueId;
            this.selectedTimeout = setTimeout(() => {
                this.selected = false;
                this.lastClicked = '';
            }, this.doubleClickTime);
        } else if (uniqueId === this.lastClicked && this.selected) {
            clearTimeout(this.selectedTimeout);
            this.selected = false;
            this.lastClicked = '';
            return true;
        }
        return false;
    }
}
