import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SchoolYearFilterData } from '../interfaces/school-year-filter-data.interface';

@Injectable()
export class ClientContextService {
  yearFilterBehaviorSubject = new BehaviorSubject<number>(0);
  printCohortText = '';
  printYearText = '';
  schoolYearFilters = new Subject<SchoolYearFilterData>();
  dateRange = null;

  constructor() { }

  /**
   * This function will broadcast the changes made whenever
   * year change is detected
   * @param year The selected year
   */
  yearFilter(year: number): any {
    this.yearFilterBehaviorSubject.next(year);
  }

  /**
   * This function will notify listeners that the change is happening
   * @returns Observable
   */
  broadcastYearFilter(): Observable<number> {
    return this.yearFilterBehaviorSubject.asObservable();
  }

}
