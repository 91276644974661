import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { GameLauncherComponent } from '../game-launcher/game-launcher.component';
import { WindowRefService } from '../windows-ref/window-ref.service';

@Injectable()
export class GameLauncherGuard implements CanDeactivate<GameLauncherComponent> {
  constructor(private winRef: WindowRefService) { }

  canDeactivate(
    component: GameLauncherComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.winRef.nativeWindow.location.href = nextState.url;
      return false;
  }
}
