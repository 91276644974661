import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CookieService} from 'ngx-cookie';

@Component({
    selector: 'app-cookie-reader',
    templateUrl: './cookie-reader.component.html',
    styleUrls: ['./cookie-reader.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CookieReaderComponent implements OnInit {

    constructor(private _cookieService: CookieService) {
    }

    getCookie(key: string) {
        return this._cookieService.get(key);
    }

    ngOnInit() {
        // console.log(this.getCookie('sdm_nav_ctx'));
    }

}
