<div class="container">
    <div *ngIf="showMessage" class="alert alert-danger mt-4 mr-auto ml-auto banner-message">
        <span class="close-btn" (click)="showMessage = false">
          <i class="fa fa-times"></i>
        </span>
        <span>
            If the app did not automatically open, please make sure it is downloaded on this device.
            <br><br>
            If the app is downloaded on this device, but will not open from the Safari browser, please launch it from the Chrome browser or contact Customer Service at 1-800-724-2222 or email digitalservice@scholastic.com.
        </span>
    </div>
    <span class="image-container" #imgContainer [ngClass]="{ 'message-active': showMessage }">
        <img src="../../assets/img/island.png" usemap="#button-map"/>
        <map name="button-map">
            <area class="image-area" shape="rect" coords="124,347,391,429" href="{{apiURL}}/launcher{{gameLaunchParams}}" routerLinkActive="active-link" alt="alttext" #areaElement>
        </map>
    </span>
</div>
